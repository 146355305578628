<template>
  <div>
    <!-- button -->
    <b-button
      style="width: 180px"
      v-b-modal.modal-prevent-closing
      variant="btn btn-primary"
    >
      <feather-icon icon="PlusIcon" /> Nouvelle Plainte
    </b-button>

    <!-- modal -->
    <b-modal
      centered
      id="modal-prevent-closing"
      ref="my-modal"
      title="Faire une nouvelle plainte"
      ok-title="Envoyer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      hide-header-close
    >
      <validation-observer ref="addProjectForm">
        <form ref="form">
          <b-form-group>
            <ky-input
              :placeholder="'Sujet'"
              :type="'text'"
              :rules="'required'"
              v-model="project.name"
            />
          </b-form-group>

          <b-form-group label="Message">
            <b-textarea
              placeholder="Description du projet"
              v-model="project.description"
            >
            </b-textarea>
          </b-form-group>
          <b-form-group label="Suggestion">
            <b-textarea
              placeholder="Description du projet"
              v-model="project.suggestion"
            >
            </b-textarea>
          </b-form-group>
          <b-form-group class="text-center">
            <button
              class="btn btn-outline-danger mr-1"
              @click.prevent="hideModal"
            >
              Fermer
            </button>
            <b-button @click="addClientProject">Valider</b-button>
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import KyInput from "@/@core/layouts/components/ky-input.vue";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      projectDefault: {
        name: "",
        start_date: "",
        end_date: "",
        desciption: "",
        suggestion: "",
      },
      project: {
        name: "",
        start_date: "",
        end_date: "",
        description: "",
      },
      errors: "",
      errorMessage: "",
      name: "",
      nameState: null,
      submittedNames: [],
    };
  },
  components: {
    ToastificationContent,
    BButton,
    KyInput,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
  },

  computed: {
    ...mapState("customer", ["projects"]),
  },
  methods: {
    async addClientProject() {
      const valid = await this.$refs.addProjectForm.validate();
      if (!valid) return;
      this.loading = true;
      this.addProject(this.$formData(this.project))
        .then((r) => {
          this.loading = true;
          this.hideModal();
          this.clearForm();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Demande envoyé avec succès ",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = true;
          this.errorModal = true;
        });
    },

    clearForm() {
      this.project = this.projectDefault;
      this.$nextTick().then((response) => {
        this.$refs.addProjectForm.reset();
      });
    },

    hideModal() {
      this.$bvModal.hide("modal-prevent-closing");
    },

    ...mapActions("customer", ["addProject"]),
  },
};
</script>
 