<template>
  <div>
    <b-card no-body>
      <b-card-body>

        <div>
          <b-row class="d-flex justify-content-between">
            <b-col cols="auto">
              <h4>
                Liste des factures <span>( {{ allCustomerInvoices.length }} )</span>
              </h4>
            </b-col>

          </b-row>
          <hr>
        </div>
        <div>
          <b-form-group label-cols-md="4" label-for="filterInput">
            <b-form-input id="filterInput" v-model="filterTable" type="search" placeholder="Rechercher..." />
          </b-form-group>
        </div>

      </b-card-body>
      <!-- Table beginning -->
    
      <b-table style="height:280px" no-border-collapse responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
        :items="allCustomerInvoices" :fields="projectsItems" show-empty empty-text="Pas de factures disponible"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :tbody-tr-class="rowClass">

        <template #cell(is_paid)="data">
          <b-form-checkbox v-model="data.item.is_paid" disabled />
        </template>

        <template #cell(created_at)="data">
          {{ getDate(data.item.created_at) }}
        </template>

        <template #cell(totalTTC)="data">
          {{ totalTTC(data.item) }}
        </template>

        <template #cell(action)="">
          <b-dropdown variant="link" no-caret class="chart-dropdown" toggle-class="p-0" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="18" class="text-body cursor-pointer" />
            </template>
            <b-dropdown-item :href="'#'" target="_blank">
              <feather-icon icon="EyeIcon" size="18" style="margin-right: 5px" />
              Voir la facture
            </b-dropdown-item>
            <!-- <b-dropdown-item @click="invoiceAvance = true">
              <feather-icon icon="DollarSignIcon" size="18" style="margin-right: 5px" />
              Avance sur facture
            </b-dropdown-item>
            <b-dropdown-item button @click="invoiceMessageModal = true">
              <feather-icon icon="SendIcon" size="18" style="margin-right: 5px" />
              Réclamer
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Affichage par page" label-cols="8" label-align="left" label-size="sm"
          label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="this.filteredItems.length" :per-page="perPage" first-number
            last-number prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-modal ref="avanceModal" no-close-on-backdrop hide-footer hide-header-close v-model="invoiceAvance" centered
      size="500px">
      <template #modal-title>
        Avance sur facture
      </template>

      <validation-observer>
        <b-form>
          <ky-input :placeholder="'Total TTC'" :type="'number'" :rules="'required'" v-model="avance.invoiceTTC" />
          <ky-input :placeholder="'Montant à avancer'" :type="'number'" :rules="'required'" v-model="avance.amount" />
          <b-form-group label="Note">
            <b-form-textarea v-model="avance.note" />
          </b-form-group>
          <div class="text-center">
            <b-button variant="outline-danger" @click="invoiceAvance = false">ANNULER</b-button>
            <b-button class="ml-1" variant="primary">ENVOYER</b-button>
          </div>
        </b-form>
      </validation-observer>

    </b-modal>
    <b-modal ref="avanceModal" no-close-on-backdrop hide-footer hide-header-close v-model="invoiceMessageModal" centered
      size="500px">
      <template #modal-title>
        Avance sur facture
      </template>

      <validation-observer>
        <b-form>
          <ky-input :placeholder="'Sujet'" :type="'number'" :rules="'required'" v-model="invoiceMessage.subject" />

          <b-form-group label="Message">
            <b-form-textarea v-model="invoiceMessage.message" />
          </b-form-group>
          <div class="text-center">
            <b-button variant="outline-danger" @click="invoiceMessageModal = false">ANNULER</b-button>
            <b-button class="ml-1" variant="primary">ENVOYER</b-button>
          </div>
        </b-form>
      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import { BProgress } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";

export default {
  props: ["items"],
  components: {
    BProgress,
    KyInput
  },
  data() {
    return {
      q: {
        customerId: JSON.parse(localStorage.getItem('customerInfo')).id,
        search: 'invoices',
      },
      choice: 'invoices',
      options: [
        { text: 'Factures', value: 'invoices' },
        { text: 'Devis', value: 'devis' }
      ],
      invoiceMessage: {
        subject: "",
        message: "",
      },
      invoiceAvance: false,
      exporting: false,
      invoiceMessageModal: false,
      rows: 0,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterTable: "",
      avance: {
        invoiceTTC: 0,
        amount: 0,
        note: "",
      },

      projectsItems: [
        { key: "num", label: "Numéro", sortable: true },
        { key: "created_at", label: "Date", sortable: true },
        { key: "totalTTC", label: "Total TTC", sortable: true },
        { key: "is_paid", label: "Soldée", sortable: true },
        { key: "action", label: "Actions", thStyle: { width: "5%" } },
      ],
      /* eslint-disable global-require */
    };
  },
  computed: {
    ...mapState('tasks', ['allCustomerInvoices']),
    sortOptions() {
      // Create an options list from our fields
      return this.projectsItems
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("auth", ["userInfo"]),

    filteredItems() {
      return (
        this.items?.filter((item) => {
          return (
            item?.name
              .toLowerCase()
              .includes(this.filterTable.toLowerCase())
          );
        }) || this.items
      );
    },
  },
  mounted() {
    this.getCustomerInvoices(this.q.customerId);
  },

  watch: {
    choice(val) {
      this.q.search = val
      this.getCustomerInvoices(this.q)
    }
  },

  methods: {

    getDate(date) {
      return moment(date).locale("fr").format("DD MMMM YYYY");
    },

    totalTTC(data) {
      var total = data.total;
      var tva = (total * data.tva) / 100;
      var tttc = total + tva;
      if (Number.isInteger(total) == true) {
        return tttc;
      } else {
        return tttc.toFixed(2);
      }
    },
    rowClass(item, type) {
      const presenceOui = "table-success text-success";
      const presenceNon = "table-danger text-danger";
      // if (!item || type !== "row") {
      //   return;
      // }

      // // eslint-disable-next-line consistent-return
      // if (item.online == true) {
      //   return presenceOui;
      // }
      // if (item.online == false) {
      //   return presenceNon;
      // }
    },


    ...mapActions("tasks", ["getCustomerInvoices"]),
  },
};
</script>
