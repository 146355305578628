<template>
  <div>
    <CustomerNavBar />
    <b-card no-body class="m-1">
      <b-tabs pills vertical lazy>
        <b-tab title="Mes Projets" active>
          <b-card-text>
            <b-card no-body border-variant="black" class="mb-2 p-1">
              <b-card-text>
                <customer-projects />
              </b-card-text>
            </b-card>
          </b-card-text>
        </b-tab>
        <b-tab title="Mes Factures">
          <b-card-text>
            <b-card no-body border-variant="black" class="mb-2">
              <b-card-text>
                <customer-invoices :items="projects" />
              </b-card-text>
            </b-card>
          </b-card-text>
        </b-tab>
        <b-tab title="Mes Devis">
          <b-card-text>
            <b-card no-body border-variant="black" class="mb-2">
              <b-card-text>
                <customer-devis :items="projects" />
              </b-card-text>
            </b-card>
          </b-card-text>
        </b-tab>
        <!-- <b-tab title="Plaintes">
            <b-card-text>
              <b-card no-body border-variant="black" class="mb-2">
                <b-card-text>
                  <customer-complaints :items="projects" />
                </b-card-text>
              </b-card>
            </b-card-text>
          </b-tab> -->
      </b-tabs>
    </b-card>

    <div class="pl-2" style="margin-top: 1%">
      <b-card>
        <app-footer />
      </b-card>
    </div>
  </div>
</template>

<script>
import KyInput from "@/@core/layouts/components/ky-input.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CustomerProjects from "./CustomerProjects.vue";
import CustomerInvoices from "./CustomerInvoices.vue";
import CustomerDevis from "./CustomerDevis.vue";
import CustomerComplaints from "./CustomerComplaints.vue";
import CustomerNavBar from "./components/CustomerNavBAr.vue";
import AppFooter from "@core/layouts/components/AppFooter.vue";
import ProjectRequest from "./ProjectRequest.vue";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ProjectRequest,
    AppFooter,
    CustomerNavBar,
    CustomerProjects,
    CustomerInvoices,
    CustomerComplaints,
    CustomerDevis,
  },
  data() {
    return {
      showThisDetails: false,
      colProjects: 12,
      colDetails: 0,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      /* eslint-disable global-require */

      /* eslint-disable global-require */
    };
  },
  computed: {

    ...mapState("customer", ["projects", "customerInfo"]),
  },
  mounted() {


  },
  methods: {
  
  },
};
</script>


<style scoped >
.table th {
  color: blue;
  border-style: none;
  background-color: antiquewhite;
}

.link:hover {
  margin-bottom: 1px;
  box-shadow: 1px 1px 5px rgb(199, 196, 196);
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .bouton {
    margin-top: 2%;
  }
}

.tabs,
.col-auto,
.tabs .col-auto {
  text-align: left !important;
  margin: 10px !important;
  padding: 10px !important;

}
</style>
