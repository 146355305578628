<template>
  <div>
    <b-card>
      <b-card-body class="p-0 m-0" style="padding:0px; margin:0px">
        <div>
          <div>
            <div>
              <b-row class="d-flex justify-content-between">
                <b-col cols="auto">
                  <h4>
                    Liste des plaintes <span>( {{ items.length }} )</span>
                  </h4>
                </b-col>
                <b-col cols="auto">
                  <b-row>
                    <b-col>
                      <ComplaintsMessage />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <hr>
            </div>
            <div>

              <b-form-group label-cols-md="4" label-for="filterInput">
                <b-form-input id="filterInput" v-model="filterTable" type="search" placeholder="Rechercher..." />
              </b-form-group>
            </div>
          </div>
        </div>
      </b-card-body>
      <!-- Table beginning -->
      <b-table no-border-collapse responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
        :items="items" :fields="projectsItems" show-empty empty-text="Pas de plaintes disponible"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered"
        :tbody-tr-class="rowClass">




        <template #cell(action)="data">
          <b-row>
            <b-col class="col-md-3">
            </b-col>
            <b-col>
              <b-avatar variant="primary" button @click="projectDetailsSideBar = true, project = data.item">
                <feather-icon icon="EyeIcon" variant="primary" size="20" />
              </b-avatar>

            </b-col>
          </b-row>
        </template>


        <template #cell(Note)="data">
          <b-form-rating disabled v-model="data.value.note" no-border class="text-warning"
            style="background-color: white"></b-form-rating>
        </template>



      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Affichage par page" label-cols="8" label-align="left" label-size="sm"
          label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="currentPage" :total-rows="this.filteredItems.length" :per-page="perPage" first-number
            last-number prev-class="prev-item" next-class="next-item" class="mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-sidebar backdrop shadow right width="500px" v-model="projectDetailsSideBar" id="sidebar-footer"
      aria-label="Sidebar with custom footer" no-header>
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">

          <b-button size="sm" @click="hide">Fermer</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <b-card>
          <template #header>
            <h2>Plainte :</h2>
          </template>
          <validation-observer>
            <b-form>
              <b-row>
                <b-col cols="12" md="12">
                  <p> Ici sera le sujet de la plainte </p>
                </b-col>
                <b-col cols="12" md="12">
                  <p><strong>Message :</strong></p>
                  <p>Ici sera le message de la plainte, <br> Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Tenetur, consequuntur reprehenderit. Laudantium tempora adipisci, sequi odit cumque fugit vitae ex
                    mollitia velit inventore consequuntur blanditiis debitis aliquid. Aut, esse voluptates!</p>

                </b-col>
                
                
              </b-row>
            </b-form>
          </validation-observer>
          Facture de plainte : <strong> {{ project.master }} </strong>
        </b-card>
      </div>
      <div>
        <b-card title="Discussion">
          
        </b-card>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import KyInput from "@/@core/layouts/components/ky-input.vue";
import { BProgress } from "bootstrap-vue";
import ComplaintsMessage from "./ComplaintsMessage.vue";

import { mapActions, mapState } from "vuex";

export default {
  props: ["items"],
  components: {
    BProgress,
    KyInput,
    ComplaintsMessage
  },
  data() {
    return {
      projectDetailsSideBar: false,
      exporting: false,
      rows: 0,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterTable: "",
      project: {},


      projectsItems: [
        { key: "name", label: "Sujet", sortable: true },
        { key: "start_date", label: "Date", sortable: true },
        { key: "master", label: "Message", sortable: true },
        { key: "action", label: "Action" },
      ],


      /* eslint-disable global-require */
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.projectsItems
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("auth", ["userInfo"]),

    filteredItems() {
      return (
        this.items?.filter((item) => {
          return (
            item?.name
              .toLowerCase()
              .includes(this.filterTable.toLowerCase())
          );
        }) || this.items
      );
    },
  },
  mounted() {
    // Set the initial number of items
    //this.getEmployees();
  },

  watch: {},

  methods: {
    onFiltered(items) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = items.length;
      this.currentPage = 1;
    },

    rowClass(item, type) {
      const is_closed = "table-success text-success";
      const is_finished = "table-danger text-danger";
      if (!item || type !== "row") {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (item.is_closed == true) {
        return is_closed;
      }
      // if (item.is_finished == false) {
      //   return is_finished;
      // }
    },

    exportToExcel() {
      this.exporting = true;
      this.exportUsersExcel().then((res) => {
        this.exporting = false;
        window.location.href = res.data;
      }).catch((err) => {
        this.exporting = false;
      });
    },

    exportToPdf() {
      this.exporting = true;
      this.exportUsersPdf().then((res) => {
        this.exporting = false;
        window.location.href = res.data;
      }).catch((err) => {
        this.exporting = false;
      });
    },

    ...mapActions("enterprise", ["getEmployees"]),
    ...mapActions("member", ["exportUsersExcel", "exportUsersPdf"]),
  },
};
</script>
